@font-face {
  font-family: 'FancyText';
  src: url('../../public/font/DancingScript-VariableFont_wght.ttf')
      format('truetype supports variations'),
    url('../../public/font/DancingScript-VariableFont_wght.ttf')
      format('truetype-variations');
}

@font-face {
  font-family: 'OtherText';
  src: url('../../public/font/Montserrat-VariableFont_wght.ttf')
      format('truetype supports variations'),
    url('../../public/font/Montserrat-VariableFont_wght.ttf')
      format('truetype-variations');
}
