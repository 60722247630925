body {
  font-family: 'OtherText', serif;
  overflow: hidden;
  height: 100vh;

  background: url(/asset/splash-dark.png) no-repeat center center/cover;
}

.title {
  font-family: 'FancyText', sans-serif;
  letter-spacing: 1px;
}

.card:not(.card-untextured) {
  background: url(/asset/texture-bg.png),
    linear-gradient(
      to bottom,
      oklch(var(--n) / var(--tw-bg-opacity)),
      oklch(var(--n) / var(--tw-bg-opacity))
    );
  color: oklch(var(--nc) / var(--tw-bg-opacity));

  &.foreground {
    --n: var(--b1);
    color: oklch(var(--bc) / var(--tw-bg-opacity));
  }

  &.foreground-2 {
    --n: var(--b3);
    color: oklch(var(--bc) / var(--tw-bg-opacity));
  }
}

.select {
  background-color: oklch(var(--b1) / var(--tw-bg-opacity));
  color: oklch(var(--bc) / var(--tw-bg-opacity));
}

.btn:not(.btn-untextured):not(.btn-outline) {
  background: url(/asset/texture-button.png),
    linear-gradient(
      to bottom,
      oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)),
      oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1))
    ) !important;

  &:hover {
    background: url(/asset/texture-button.png),
      linear-gradient(
        to bottom,
        color-mix(
          in oklab,
          oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%,
          black
        ),
        color-mix(
          in oklab,
          oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%,
          black
        )
      ) !important;
  }
}

.label-checkbox {
  justify-content: left;

  .label-text {
    @apply ml-3;
  }
}

progress::-webkit-progress-value {
  transition: 250ms width;
}
